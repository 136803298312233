export const A11Y_REFLOW_TRANS_FEATURE = 'Accessibility'

export const A11Y_REFLOW_BANNER_TEXT_KEY = 'Accessibility_Banner_Reflow_Text'
export const A11Y_REFLOW_BANNER_TEXT_DEFAULT =
	'Looks like you’ve zoomed in to view this site. Switching to mobile view will give you a much better experience.'

export const A11Y_REFLOW_BUTTON_STAY_HERE_KEY = 'Accessibility_Banner_Reflow_Secondary_CTA'
export const A11Y_REFLOW_BUTTON_STAY_HERE_DEFAULT = 'Stay Here'

export const A11Y_REFLOW_BUTTON_MOVE_VIEW_KEY = 'Accessibility_Banner_Reflow_Primary_CTA'
export const A11Y_REFLOW_BUTTON_MOVE_VIEW_DEFAULT = 'Go to Mobile View'
